document.addEventListener('DOMContentLoaded', function() {
    const accordionItems = document.querySelectorAll('.repeater-block .accordion-item .accordion-title');
    const videoPreviewContainer = document.querySelector('.repeater-block .video-preview');

    let videoPreview = videoPreviewContainer ? (videoPreviewContainer.querySelector('iframe') || videoPreviewContainer.querySelector('video')) : null;

    accordionItems.forEach(button => {
        button.addEventListener('click', function() {
            const index = this.getAttribute('data-index');
            const youtubeLink = this.getAttribute('data-youtube');
            let mp4Link = this.getAttribute('data-mp4');
            const activeItem = document.querySelector('.repeater-block .accordion-item.active');
            const newItem = this.parentElement;

            if (activeItem) {
                activeItem.classList.remove('active');
                activeItem.querySelector('.accordion-content').style.display = 'none';
            }
            newItem.classList.add('active');
            newItem.querySelector('.accordion-content').style.display = 'block';

            if (videoPreviewContainer) {
                if (!youtubeLink && !mp4Link) {
                    // Find the first available video link
                    const firstVideoLink = [...accordionItems].map(item => item.getAttribute('data-youtube')).find(link => link) || '';
                    const firstMp4Link = [...accordionItems].map(item => item.getAttribute('data-mp4')).find(link => link) || '';
                    if (firstMp4Link) {
                        mp4Link = firstMp4Link;
                    } else {
                        youtubeLink = firstVideoLink;
                    }
                }

                if (mp4Link) {
                    if (videoPreview.tagName.toLowerCase() === 'iframe') {
                        videoPreviewContainer.innerHTML = `<video width="560" height="315" controls><source src="${mp4Link}" type="video/mp4"></video>`;
                        videoPreview = videoPreviewContainer.querySelector('video');
                    } else {
                        videoPreview.querySelector('source').src = mp4Link;
                        videoPreview.load();
                    }
                } else if (youtubeLink) {
                    const newVideoSrc = youtubeLink.replace("watch?v=", "embed/");
                    if (videoPreview.tagName.toLowerCase() === 'video') {
                        videoPreviewContainer.innerHTML = `<iframe width="560" height="315" src="${newVideoSrc}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                        videoPreview = videoPreviewContainer.querySelector('iframe');
                    } else {
                        videoPreview.src = newVideoSrc;
                    }
                } else {
                    videoPreview.src = '';
                }
            }
        });
    });
});
